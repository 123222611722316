var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pt-0 px-5 px-md-12", attrs: { fluid: "" } },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column flex-sm-row align-sm-center justify-sm-space-between"
        },
        [
          _c("span", { staticClass: "pl-3 section-title" }, [
            _vm._v(_vm._s(_vm.$t("views.import.customer.title")))
          ])
        ]
      ),
      _c(
        "v-card",
        { staticClass: "mt-4 mb-2 mx-0 mx-md-1 pa-4", attrs: { flat: "" } },
        [
          _c("v-card-text", { staticClass: "py-0" }, [
            _c("p", { staticClass: "mt-1" }, [
              _vm._v(_vm._s(_vm.$t("views.import.customer.description1")))
            ]),
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "primary--text",
                    attrs: {
                      outlined: "",
                      height: "36px",
                      width: "200px",
                      href: "/import_customer_template.csv",
                      download: ""
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("views.import.customer.download_template"))
                    )
                  ]
                )
              ],
              1
            ),
            _c("p", { staticClass: "mt-6 mb-4" }, [
              _vm._v(_vm._s(_vm.$t("views.import.customer.description2")))
            ]),
            _c(
              "div",
              [
                _c("v-checkbox", {
                  ref: "update_register_customers",
                  staticClass: "primary--text",
                  attrs: {
                    label: _vm.$t(
                      "views.import.customer.update_register_customers"
                    )
                  },
                  model: {
                    value: _vm.params.update_customers,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "update_customers", $$v)
                    },
                    expression: "params.update_customers"
                  }
                }),
                _c("p", { staticClass: "text-sm" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "views.import.customer.update_register_customers_hint"
                      )
                    )
                  )
                ])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex " },
              [
                _c("FileInput", {
                  staticClass: "mt-1 mx-auto",
                  attrs: {
                    type: "import-customers",
                    maxSizeMB: 512,
                    accept: ".csv",
                    params: _vm.params
                  },
                  on: { upload: _vm.onUpload }
                })
              ],
              1
            ),
            _c("p", { staticClass: "mt-5 font-weight-bold" }, [
              _vm._v(_vm._s(_vm.$t("views.import.customer.obs")))
            ]),
            _c("ul", { staticClass: "mb-2" }, [
              _c("li", [_vm._v(_vm._s(_vm.$t("views.import.customer.obs1")))]),
              _c("li", [_vm._v(_vm._s(_vm.$t("views.import.customer.obs2")))]),
              _c("li", [_vm._v(_vm._s(_vm.$t("views.import.customer.obs3")))])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }