import { render, staticRenderFns } from "./Customer.vue?vue&type=template&id=14965004&scoped=true&"
import script from "./Customer.vue?vue&type=script&lang=js&"
export * from "./Customer.vue?vue&type=script&lang=js&"
import style0 from "./Customer.vue?vue&type=style&index=0&id=14965004&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14965004",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardText,VCheckbox,VContainer})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-admin/cplug-fid-front-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('14965004')) {
      api.createRecord('14965004', component.options)
    } else {
      api.reload('14965004', component.options)
    }
    module.hot.accept("./Customer.vue?vue&type=template&id=14965004&scoped=true&", function () {
      api.rerender('14965004', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/import/Customer.vue"
export default component.exports