<template>
    <v-container fluid class="pt-0 px-5 px-md-12">
        <div class="d-flex flex-column flex-sm-row align-sm-center justify-sm-space-between">
            <span class="pl-3 section-title">{{ $t('views.import.customer.title') }}</span>
        </div>
        <v-card flat class="mt-4 mb-2 mx-0 mx-md-1 pa-4">
            <v-card-text class="py-0">
                <p class="mt-1">{{ $t('views.import.customer.description1') }}</p>

                <div class="text-center">
                    <v-btn outlined height="36px" width="200px" class="primary--text"
                    href="/import_customer_template.csv" download
                    >{{ $t('views.import.customer.download_template') }}</v-btn>
                </div>

                <p class="mt-6 mb-4">{{ $t('views.import.customer.description2') }}</p>

                <div>
                    <v-checkbox
                        class="primary--text"
                        ref="update_register_customers"
                        v-model="params.update_customers"
                        :label="$t('views.import.customer.update_register_customers')"
                    ></v-checkbox>

                    <p class="text-sm">{{ $t('views.import.customer.update_register_customers_hint') }}</p>

                    <!-- <v-checkbox
                        class="primary--text"
                        ref="update_register_customers"
                        v-model="params.reset_balance"
                        :label="$t('views.import.customer.reset_balance')"
                        :disabled="!params.update_customers"
                    ></v-checkbox>
                    <p class="text-sm">{{ $t('views.import.customer.reset_balance_hint') }}</p> -->
                </div>

                <div class="d-flex ">
                    <FileInput
                        type="import-customers"
                        :maxSizeMB="512"
                        accept=".csv"
                        @upload="onUpload"
                        class="mt-1 mx-auto"
                        :params="params"
                    />
                </div>

                <p class="mt-5 font-weight-bold">{{ $t('views.import.customer.obs') }}</p>
                <ul class="mb-2">
                    <li>{{ $t('views.import.customer.obs1') }}</li>
                    <li>{{ $t('views.import.customer.obs2') }}</li>
                    <li>{{ $t('views.import.customer.obs3') }}</li>
                </ul>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import FileInput from '@/components/inputs/FileInput'

export default {
    components: { FileInput },
    mixins: [ HasErrorHandlerMixin ],
    data: () => ({
        params: {
            update_customers: false,
            reset_balance: false,
        }
    }),
    methods: {
        onUpload(_, __, res) {
            console.log('res', res)

            // Quando terminar o upload, vai
            this.$router.push({ name: 'import' })
        },
    },
    watch: {
        'params.update_customers' (v) {
            if (!v)
                this.params.reset_balance = false
        }
    }
}
</script>

<style scoped lang="scss">
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px !important;
}
</style>